<template>
<ModalForm
    id="modalFormEditUser"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <!-- EMAIL -->
    <div class="fv-row rowItemControl">
        <label class="required fs-6 fw-bold mb-2">Email</label>
        <Field  name="Email" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    v-bind="field"
                    type="text" 
                    placeholder="Email"
                    :validate-event="false"
                    :disabled="readOnly"
                    :model-value="value"
                />
            </el-form-item>
        </Field> 
    </div>  

    <!-- FIRSTNOMBRE -->
    <div class="fv-row rowItemControl">
        <label class="required fs-6 fw-bold mb-2">Nombre</label>
        <Field  name="FirstName" v-slot="{value, field, errorMessage}">
            <el-form-item :error="errorMessage">
                <el-input
                    v-bind="field"
                    type="text" 
                    placeholder="Nombre"
                    :validate-event="false"
                    :disabled="readOnly"
                    :model-value="value"
                />
            </el-form-item>
        </Field> 
    </div>

    <!-- LASTNAME Y PHONENUMBER-->
    <RowForm class="paddingTop" :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Apellido</label>
            <Field  name="LastName" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        class="itemControlMiddle"
                        type="text" 
                        placeholder="Apellido"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">Teléfono</label>
            <Field  name="PhoneNumber" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        class="itemControlMiddle"
                        type="text" 
                        maxlength="10"
                        placeholder="Teléfono"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
    </RowForm>

    <div class="fv-row rowItemControl paddingTop">
        <label class="required fs-6 fw-bold mb-2">Roles</label>
        <MultiSelectOption 
            name="RolesToAdd"
            :options="comboRoles"
            :disabled="readOnly"
            @changeSelectValue="handleChangeRoles"
        />
    </div>
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, onUpdated, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../components/c-modal-form/ModalFormMaster.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import MultiSelectOption from "../../components/c-form-elements/MultiSelectOption.vue";

export default defineComponent({
    components:{
        Field,
        ModalForm,
        RowForm,
        MultiSelectOption
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Artículo"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const { mode, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref(null);
        let controller = "users";
        const roles = ref([]);
        let RolesToAdd = [];
        let RolesToRemove = [];
        const validationSchema = Validations.object().shape({
            Email: Validations.string().required().label("Email"),
            FirstName: Validations.string().required().label("FirstName"),
            LastName: Validations.string().required().label("LastName"),
            PhoneNumber: Validations.string().required().min(10).max(10).label("PhoneNumber"),
            RolesToAdd: Validations.array(Validations.string()).required().label("RolesToAdd")
        });

        onBeforeMount(async() => {
            await getComboOptions("roles", roles, "");
        });

        watch(currentOpenedItem, (value) => {
            if(value) {
                RolesToAdd = [];
                RolesToRemove = [];
            }
        });

        //VARIABLES COMPUTADAS
        const initialValues = computed(() => {
            let item = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                item.Email = currentOpenedItem.value.email;
                item.Name = currentOpenedItem.value.name;
                item.FirstName = currentOpenedItem.value.firstName;
                item.LastName = currentOpenedItem.value.lastName;
                item.PhoneNumber = currentOpenedItem.value.phoneNumber;
                item.RolesToAdd = currentOpenedItem.value.roles;
            }
            
            return item;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboRoles = computed(() => { return roles.value; });


        //FUNCIONES
        const initDefaults = () => {
            modalForm.value.$refs.form.resetForm(); 
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            item.RolesToAdd = item.RolesToAdd.map(r => {
                let role = {};
                role.Id = r;
                return role;
            });
            ApiService.post(controller, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${currentOpenedItem.value.id}`;
            item.Id = currentOpenedItem.value.id;
            item.RolesToAdd = [...RolesToAdd];
            item.RolesToRemove = [...RolesToRemove];
            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {       
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboOptions = async(resource, container, parentId = "", methodName = "", filters = "") => {
            let criteriaSearch = `?Criteria.Active=true${filters != "" ? `&${filters}` : ""}`;
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}${methodName}${criteriaSearch}`;
            await ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleChangeRoles = (evtRoles) => {
            if((mode.value === "M") && currentOpenedItem.value) {
                currentOpenedItem.value.roles.forEach(item => {
                    if(!evtRoles.some(r => r === item) && !RolesToRemove.some(rtr => rtr.Id === item)) {
                        RolesToRemove.push({ Id: item });
                    }
                });
                evtRoles.forEach(item => {
                    if(!currentOpenedItem.value.roles.some(r => r === item) && !RolesToAdd.some(rta => rta.Id === item)) {
                        RolesToAdd.push({ Id: item });
                    }
                });
            }
        };

        return {
            editForm,
            modalForm,
            validationSchema,

            //Variables computadas
            initialValues,
            readOnly,
            isEditing,
            comboRoles,

            //Funciones
            initDefaults, 
            handleCrudAction,
            handleChangeRoles
        }
    },
})
</script>

<style lang="scss">

    .itemControlFull {
        width: 250px;
    }

    .itemControlMiddle {
        min-width: 250px;
    }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

    .paddingBottom {
        padding-bottom: 50px;
    }

    .divRoles {
        display: flex;
        flex-direction: column;
    }

    .divRowRoles {
        display: flex;
    }

</style>

